// @flow

import React from "react";
import {useAction, useAtom} from "@reatom/react";
import {nextStep, StepsAtom} from "../atoms/Steps";

export const GoNext = () => {
  const stepsState = useAtom(StepsAtom);
  const currentStep = stepsState.steps[stepsState.currentStepId - 1];

  const handleGoNext = useAction(() => nextStep({step: currentStep}), [currentStep])
  return (
    <li><a onClick={handleGoNext} href="#">GoNext&rarr;</a></li>
  );
}