// @flow

import React from "react";
import {useAction, useAtom} from "@reatom/react";
import {prevStep, StepsAtom} from "../atoms/Steps";

export const GoBack = () => {
  const stepsState = useAtom(StepsAtom);
  const currentStep = stepsState.steps[stepsState.currentStepId - 1];

  const handleGoBack = useAction(() => prevStep({step: currentStep}), [currentStep])
  return (
    <li><a href="#" onClick={handleGoBack}>&larr;GoBack</a></li>
  );
}
